window.enquiryPopup = function(page) {
  $('#enquiryForm h2 .text-success').text(page == 'Homepage' ? 'Business Loan' : page)
  $('#enquiry_page').val(page)
  $('#enquiryForm').modal('show')
}

window.scrollToEl = function(el) {
  smoothScroll($(el)[0], {
    offset: 100,
    getEasingFunction: function(targetPosition) {
      return function(stepCount) {
        return stepCount * 1;
      };
    }
  });
}

$(document).on('turbolinks:load', function() {
})
